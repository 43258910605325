export const INIT_USERNAME = 'INIT_USERNAME';
export const SET_USER_ID = 'SET_USER_ID';
export const FETCH_USER_INFO = 'FETCH_USER_INFO';
export const SET_HOME_ID = 'SET_HOME_ID';
export const USER_FETCH_DATA = 'USER_FETCH_DATA';
export const ON_NEW_CHECKIN_RECEIVED = 'ON_NEW_CHECKIN_RECEIVED';
export const ON_NEW_CHECKIN_CLICKED = 'ON_NEW_CHECKIN_CLICKED';
export const ON_CHECKIN_DELETE_RECEIVED = 'ON_CHECKIN_DELETE_RECEIVED';
export const ON_CHECKIN_DELETE_CLICKED = 'ON_CHECKIN_DELETE_CLICKED';
export const ON_HOME_DELETE = 'ON_HOME_DELETE';
export const ON_HOME_UNSUBSCRIBE = 'ON_HOME_UNSUBSCRIBE';
export const ON_HOME_UPDATE_IMAGE = 'ON_HOME_UPDATE_IMAGE';
export const POST_COMMENT = 'POST_COMMENT';

export const UPDATE_CHECKIN_RATING_MANUALLY = 'UPDATE_CHECKIN_RATING_MANUALLY';
export const UPDATE_CHECKIN_RATING = 'UPDATE_CHECKIN_RATING';
export const UI_LOADING = 'UI_LOADING';
export const UI_LOADING_COMPLETE = 'UI_LOADING_COMPLETE';
export const ON_CHECKOUT = 'ON_CHECKOUT';
export const ADDED_HOME_BY_CODE = 'ADDED_HOME_BY_CODE';
export const ADDED_HOME_BY_ADDRESS = 'ADDED_HOME_BY_ADDRESS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const USER_UPDATE_VIEW_MODE = 'USER_UPDATE_VIEW_MODE';
export const UPDATE_CHECKIN = 'UPDATE_CHECKIN';
export const ON_MORE_CHECKIN_RECEIVED = 'ON_MORE_CHECKIN_RECEIVED';
export const ON_DISMISS_ALERT_MESSAGE = 'ON_DISMISS_ALERT_MESSAGE';
export const ON_SHOW_ALERT_MESSAGE = 'ON_SHOW_ALERT_MESSAGE';

export const AS_CHECKIN_NEW_COMMENT = 'AS_CHECKIN_NEW_COMMENT';
export const AS_CHECKIN_UPDATE = 'AS_CHECKIN_UPDATE';
export const AS_CHECKIN_NEW = 'AS_CHECKIN_NEW';
export const AS_CHECKIN_DELETE = 'AS_CHECKIN_DELETE';
export const AS_COMMENT_NEW = 'AS_COMMENT_NEW';
