import React, { Component } from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Form,
    FormGroup,
    FormText,
    Input,
    Button
} from 'reactstrap';

import { connect } from 'react-redux';
import { ADDED_HOME_BY_CODE } from '../../../store/actions.js';
import { saveCode, saveCodeForVisitors } from '../../../utils/graphQL/homeQL.js';
import PropTypes from 'prop-types';
import { TSNonNullExpression } from 'babel-types';

class HouseCodeEntry extends Component {

    code = null;

    handleCodeChange = (event) => {
        this.code = event.target.value;
    }

    onSaveCode = (home) => {
        this.props.onSaveCodeComplete(home);
        this.props.onModalClose();
    }

    saveCode = () => {
        if (this.props.ownerview === 'owner') {
            saveCode(this.props.user, this.code, this.props.onSaveCode);
        } else if (this.props.ownerview === 'visitor') {
            saveCodeForVisitors(this.props.user, this.code, this.onSaveCode);
        }
    }

    render() {

        let codeFooter = this.code !== TSNonNullExpression ? (
            <CardFooter>
                <Button type="submit" color="primary" className="btn-pill" onClick={() => this.saveCode()}>Save changes</Button>
            </CardFooter>
        ) : null;

        return (
            <Card>
                <CardHeader>
                    <strong>Maxidomo code</strong>
                </CardHeader>
                <CardBody>
                    <Form action="" method="post" encType="multipart/form-data" className="form-horizontal">
                        <FormGroup row>
                            <Col xs="12" md="12">
                                <Input
                                    type="text"
                                    id="text-input"
                                    name="text-input"
                                    placeholder="Enter your code..."
                                    onChange={event => this.handleCodeChange(event)}
                                />
                                <FormText color="muted">Example: J8DD5</FormText>
                            </Col>
                        </FormGroup>
                    </Form>
                </CardBody>
                {codeFooter}
            </Card>
        )
    }
}

HouseCodeEntry.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
    }),
    onModalClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        ownerview: state.user.ownerview
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSaveCodeComplete: (value) => {
            dispatch({ type: ADDED_HOME_BY_CODE, value: value })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HouseCodeEntry);