import React, { Component } from 'react';
import { Card, CardBody, Col, Input, Button, FormGroup } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ON_CHECKOUT } from '../../../store/actions.js';
import '../../../aws-config.js';
import PleaseWait from '../../ui/PleaseWait.js';
import { newCheckout } from '../../../utils/graphQL/checkinQL.js';
import { getCheckinFromCheckinList, getCheckinRecipientsForActionStream } from '../../../utils/helper.js';

class CheckoutPoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            processing: false
        };
    }

    checkoutCompleted = () => {
        this.setState({ processing: false });
        this.props.postedCheckin();
    }

    newCheckout = () => {
        this.setState({ processing: true });

        const checkedInHome = this.props.homes.concat(this.props.places).map(value => this.props.user.activehomeid === value.id ? value : null);

        const theCheckin = {
            ...getCheckinFromCheckinList(this.props.user.activecheckin, this.props.checkins),
            home: (checkedInHome !== null ? { ...checkedInHome[0] } : null)
        };

        const checkoutActionStreamRecipients = getCheckinRecipientsForActionStream(theCheckin, this.props.user.id)

        newCheckout(this.props.user,
            theCheckin,
            checkoutActionStreamRecipients,
            this.checkoutCompleted,
            this.props.onCheckoutCreated);
    }

    render() {

        const checkedInAddress = this.props.homes.concat(this.props.places).map(value => this.props.user.activehomeid === value.id ? value.address : null);

        const postingForm = (
            <FormGroup row key='formpost'>
                <Col style={{ textAlign: 'center' }}>
                    <Input
                        type="hidden"
                        name="select"
                        id="select"
                        value={this.props.homes.map((value, index) => {
                            return (this.props.user.activehomeid === value.id ?
                                value.id : null);
                        })}
                    >
                    </Input>
                    <Button
                        type="submit"
                        className="btn-pill"
                        color="danger"
                        onClick={() => this.newCheckout()}>
                        Check-out
                    </Button>
                </Col>
            </FormGroup >);

        const postingBlock = checkedInAddress === null ? null :
            (<p style={{ textAlign: 'center' }} key='message'>You are currently checked in at <strong>{checkedInAddress}.</strong></p>);

        return this.state.processing ? <PleaseWait /> : (<Card className="text-black bg-default" >
            <CardBody>
                {[postingBlock, postingForm]}
            </CardBody>
        </Card>);
    }
}
CheckoutPoster.propTypes = {
    postedCheckin: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        checkins: state.checkins,
        homes: state.homes,
        places: state.places
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCheckoutCreated: (value) => {
            dispatch({ type: ON_CHECKOUT, value: value })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPoster);

