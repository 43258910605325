import React from 'react';
import { Row, Col } from 'reactstrap';

import { GridLoader } from 'react-spinners';

const PleaseWait = () =>
    (<>
        <Row>
            <Col style={{ textAlign: 'center' }}>
                Updating... Please wait.</Col></Row>
        <Row>
            <Col style={{ textAlign: 'center' }}>
                <GridLoader
                    size={5}
                    loading={true}
                /></Col>
        </Row>
    </>);

export default PleaseWait;