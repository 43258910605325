import { API, graphqlOperation } from 'aws-amplify';
import { AS_CHECKIN_NEW, AS_CHECKIN_DELETE, AS_CHECKIN_UPDATE, AS_COMMENT_NEW } from '../../store/actions';

export const createActionStreamQL = `mutation CreateActionStream($input: CreateActionStreamInput!) {
    createActionStream(input: $input) {
      id
      originatorId
      recipientId
      payload
      streamType
    }
  }
  `;

export const onCreateActionStream = `subscription OnCreateActionStream($recipientId: String!) {
        onCreateActionStream(recipientId: $recipientId) {
          id
          originatorId
          recipientId
          payload
          streamType
        }
      }
      `;

export const putActionStreamId = (id) => {
    sessionStorage.setItem("AS_" + id, "1");
}

export const actionStreamIdExists = (id) => {
    return ("1" === sessionStorage.getItem("AS_" + id));
}

export const deleteActionStreamId = (id) => {
    sessionStorage.removeItem("AS_" + id);
}


export const createActionStreamForUsers = (originatorId, recipients, payload, streamType, includeOriginator = true) => {

    if (includeOriginator && recipients.indexOf(originatorId) === -1)
        recipients.push(originatorId);

    for (const recipientId of recipients) {
        API.graphql(graphqlOperation(createActionStreamQL, { input: { originatorId: originatorId, recipientId: recipientId, payload: JSON.stringify(payload), streamType: streamType } }))
            .then(response => {
                putActionStreamId(response.data.createActionStream.id);
            }).catch(error => {
                console.log("Error createActionStream", error);
            });
    }
}

export const createActionStream = (originatorId, recipientId, payload, streamType) => {
    API.graphql(graphqlOperation(createActionStreamQL, { input: { originatorId: originatorId, recipientId: recipientId, payload: JSON.stringify(payload), streamType: streamType } }))
        .catch(error => {
            console.log("Error createActionStream", error);
        });
}

export const subscribeToActionStream = (userId,
    onActiveStreamCommentReceivedFn,
    onActiveStreamCheckinUpdateFn,
    onActiveStreamCheckinNewFn,
    onActiveStreamCheckinDeleteFn) => {
    API.graphql(
        graphqlOperation(onCreateActionStream, { recipientId: userId })
    ).subscribe({
        next: (eventData) => {
            const actionStreamData = eventData.value.data.onCreateActionStream;
            if (actionStreamIdExists(actionStreamData.id) === false) {
                const deltaSyncPayload = JSON.parse(actionStreamData.payload);
                switch (actionStreamData.streamType) {
                    case AS_COMMENT_NEW:
                        onActiveStreamCommentReceivedFn(deltaSyncPayload);
                        break;
                    case AS_CHECKIN_UPDATE:
                        onActiveStreamCheckinUpdateFn(deltaSyncPayload);
                        break;
                    case AS_CHECKIN_NEW:
                        onActiveStreamCheckinNewFn(deltaSyncPayload);
                        break;
                    case AS_CHECKIN_DELETE:
                        onActiveStreamCheckinDeleteFn(deltaSyncPayload);
                        break;
                    default:
                        break;
                }
            } else {
                deleteActionStreamId(actionStreamData.id);
            }
        }
    });
}