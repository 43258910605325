import React, { Component } from 'react';
import { Alert, Fade } from 'reactstrap';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ON_DISMISS_ALERT_MESSAGE } from '../../store/actions';

class AlertMessage extends Component {

    onDismiss = () => {
        if (this.props.alertmessage.open === true) {
            this.props.dismissAlert();
        }
    }

    componentDidUpdate = () => {
        setTimeout(this.onDismiss, 5000);
    }

    render() {
        return (
            <Fade in={this.props.alertmessage.open}>
                <Alert
                    color="primary"
                    isOpen={this.props.alertmessage.open}
                    toggle={this.onDismiss}>
                    {this.props.alertmessage.text}
                </Alert></Fade>
        )
    }
}

AlertMessage.propTypes = {

};

const mapStateToProps = (state) => {
    return {
        alertmessage: state.ui.alertmessage
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dismissAlert: () => {
            dispatch({ type: ON_DISMISS_ALERT_MESSAGE });
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);