export const NO_HOME_ID = 'empty';
export const NO_PHONE_NUMBER = 'empty';
export const NO_EMAIL_ADDRESS = 'empty';
export const NO_HOME_ADDRESS = '';
export const EMPTY_PROFILE = {};
export const NO_ACTIVE_CHECKIN = 'NONE';
export const NO_ACTIVE_HOME = 'NONE';
export const LEFT_HOME_DEFAULT = '0';
export const EMPTY_MESSAGE = '_EMPTY';
export const GREEN_COLOR = 'rgb(77, 189, 116)';
export const BLUE_COLOR = 'rgb(32, 168, 216)';
export const RED_COLOR = 'rgb(248, 108, 107)';
export const INACTIVE_COLOR = 'rgb(230, 230, 230)';