import React from 'react';
import DefaultLayoutMaxidomo from './containers/DefaultLayoutMaxidomo';

const Wall = React.lazy(() => import('./views/Maxidomo/House/Wall/Wall'));
const Team = React.lazy(() => import('./views/Maxidomo/Team/Team'));
const User = React.lazy(() => import('./views/Maxidomo/User/User'));
// const Homes = React.lazy(() => import('./views/Maxidomo/Homes/Homes'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/wall', exact: true, name: 'Wall', component: Wall },
  { path: '/wall/:homeId', exact: true, name: 'Wall', component: Wall },
  { path: '/user/:userName', exact: true, name: 'Profile', component: User },
  { path: '/team', exact: true, name: 'Team', component: Team },
  { path: '/user', exact: true, name: 'Profile', component: User },
  // { path: '/homes', exact: true, name: 'Homes', component: Homes },
  { path: '/', exact: true, name: 'Home', component: DefaultLayoutMaxidomo },
];

export default routes;
