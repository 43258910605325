export const MakeId = (outPutLength = 5, lowerCase = false) => {
  var text = "";
  var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < outPutLength; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return (lowerCase === false) ? text.toUpperCase() : text.toLowerCase();
}

export const scrollToTop = () => {
  window.scrollTo(0, 0);
}

export const ExtractHomeIndexFromHomeId = (homes, homeId) => {
  let toCompare;
  // TODO I don't like that this can be used on .home.id and .id
  const findRes = homes.findIndex((thisRecord) => {
    toCompare = (typeof thisRecord.id !== "undefined") ? thisRecord : thisRecord.home;
    try {
      return (toCompare.id === homeId);
    } catch (err) {
      console.log("ExtractHomeIndexFromHomeId issue", err);
      return false;
    }
  });
  return (findRes > 0) ? findRes : 0;
}

export const timeIntervalFriendly = (entered, left) => {
  const inHouse = parseInt(entered);
  const outHouse = parseInt(left);
  const diff = (outHouse - inHouse) / 1000;
  const hoursSpent = Math.floor(diff / 3600);
  const minutesSpent = Math.floor((diff - 3660 * hoursSpent) / 60);
  const secondsSpent = Math.floor(diff - 3660 * hoursSpent - 60 * minutesSpent);
  return (hoursSpent > 0 ? hoursSpent + ' hour' + (hoursSpent > 1 ? 's ' : ' ') : '') + (minutesSpent > 0 ? minutesSpent + ' minute' + (minutesSpent > 1 ? 's ' : ' ') : '') + (secondsSpent > 0 ? secondsSpent + ' second' + (secondsSpent > 1 ? 's' : '') : '');

}

export const isLandlordOfHome = (userId, home) => {
  if (typeof home.landlords !== undefined && typeof home.landlords.items !== undefined) {
    for (const theLandlords of home.landlords.items) {
      if (theLandlords.id === userId) {
        return true;
      }
    }
  }
  return false;
}

export const isVisitorOfHome = (userId, home) => {
  if (typeof home.visitors !== undefined && typeof home.visitors.items !== undefined) {
    for (const theVisitors of home.visitors.items) {
      if (theVisitors.id === userId) {
        return true;
      }
    }
  }
  return false;
}

export const landlordIdsOfHome = (home) => {
  if (typeof home.landlords !== undefined && typeof home.landlords.items !== undefined) {
    return home.landlords.items.map(thePerson => thePerson.landlord.id);
  }
  return [];
}

export const getCheckinFromCheckinList = (id, checkins) => {
  const res = checkins.filter(theCheckin => theCheckin.id === id);
  return (res[0] !== undefined ? res[0] : null);
}

export const getCheckinRecipientsForActionStream = (theCheckin, originatorId) => {
  if (theCheckin.user === undefined) {
    return [];
  } else {
    if (theCheckin.user.id === originatorId) {
      // this is the person who checked in who's commenting
      if (isLandlordOfHome(originatorId, theCheckin.home)) {
        // only alert other landlords
        return landlordIdsOfHome(theCheckin.home).filter(landlordId => landlordId !== originatorId);
      } else {
        // alert all lanlords
        return landlordIdsOfHome(theCheckin.home).filter(landlordId => landlordId !== originatorId);
      }
    } else {
      // this is a landlord responding to a visitor's commenting
      return [theCheckin.user.id];
    }
  }
}

export const getLandlordRecipientsForActionStream = (home, originatorId) => {
  return landlordIdsOfHome(home).filter(landlordId => landlordId !== originatorId);
}

export const getVisitorRecipientsForActionStream = (home, originatorId) => {
  return isVisitorOfHome(home).filter(visitorId => visitorId !== originatorId);
}

export const checkinWithHome = (checkin, homes, places) => {
  return { ...checkin, home: places.concat(homes).filter(theHome => theHome.id === checkin.targetHomeId)[0] };
}

export const checkinWithUser = (checkin, users) => {
  if (checkin.user.username !== undefined) {
    return checkin;
  } else {
    const theUser = users.filter(theUser => theUser.id === checkin.user.id)[0];
    return { ...checkin, user: theUser };
  }
}