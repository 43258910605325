import { API, graphqlOperation } from 'aws-amplify';
import { MakeId, getCheckinRecipientsForActionStream, getLandlordRecipientsForActionStream } from '../helper.js';
import { createActionStreamForUsers } from './actionStreamQL.js';
import { AS_CHECKIN_NEW, AS_CHECKIN_DELETE, AS_COMMENT_NEW, AS_CHECKIN_UPDATE } from '../../store/actions.js';
import { LEFT_HOME_DEFAULT, NO_ACTIVE_CHECKIN, NO_ACTIVE_HOME } from '../../constants.js';

export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
        id
        username
        phonenumber
        email
      }
    }`;

export const deleteHomeLandlordQL = `mutation DeleteHomeLandlord($input: DeleteHomeLandlordInput!) {
      deleteHomeLandlord(input: $input) {
          home {
              id
          }
        }
      }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              entered
              left
              rating {
                id
                scale
                feedback
                user {
                  id
                  username
                }
              }
            }
          }
          `;

const createCheckinQL = `mutation CreateCheckin($input: CreateCheckinInput!) {
            createCheckin(input: $input) {
              id
              entered
              targetHomeId
              left
              user {
                id
              }
            }
          }
          `;

const updateActiveCheckinQL = `mutation UpdateUser($input: UpdateUserInput!) {
            updateUser(input: $input) {
              id
            }
          }
          `;

const updateCheckinQL = `mutation UpdateCheckin($input: UpdateCheckinInput!) {
            updateCheckin(input: $input) {
              id
              entered
              left
            }
          }
          `;

export const getMoreCheckinsForHome = `query GetHome($id: ID!, $limit: Int, $nextToken: String) {
            getHome(id: $id) {
                    id
                    checkins (sortDirection: DESC, limit: $limit, nextToken: $nextToken) {
                        nextToken
                        items {
                            id
                            home {
                                id
                                address
                                images {
                                    items {
                                        uri
                                    }
                                }
                            }
                            entered
                            left
                            comments (sortDirection: ASC) {
                                items {
                                    content
                                    date
                                    user {
                                        id
                                        username
                                    }
                                }
                            }
                            user {
                                id
                                username
                            }
                        }
                    }
                }
            }
          `;

export const getMoreCheckinsForPlace = `query GetHome($id: ID!, $authorId: String, $limit: Int, $nextToken: String) {
            getHome(id: $id) {
                    id
                    checkins (sortDirection: DESC, limit: $limit, nextToken: $nextToken, filter: {
                        authorId: {
                          eq: $authorId
                        }
                      }) {
                        nextToken
                        items {
                            id
                            home {
                                id
                                address
                                images {
                                    items {
                                        uri
                                    }
                                }
                            }
                            entered
                            left
                            comments (sortDirection: ASC) {
                                items {
                                    id
                                    content
                                    date
                                    user {
                                        id
                                        username
                                    }
                                }
                            }
                            user {
                                id
                                username
                            }
                        }
                    }
                }
            }
          `;

export const getCheckinQL = `query GetCheckin($id: ID!) {
            getCheckin(id: $id) {
              id
              home {
                id
                address
                images {
                    items {
                        uri
                    }
                }
              }
              user {
                id
                username
              }
              entered
              left
              lastUpdate
              comments {
                items {
                    id
                    content
                    date
                    user {
                        id
                        username
                    }
                }
              }
              rating {
                id
                scale
                feedback
              }
            }
          }
          `;

export const saveCheckinRating = (checkin, userId, rating, onSaveCheckinRating) => {
    const when = '' + (new Date()).getTime();
    if (checkin.rating === null) {
        // we create a new rating
        API.graphql(graphqlOperation(`mutation CreateRating($input: CreateRatingInput!) {
            createRating(input: $input) {
              id
              scale
            }
          }
          `, {
                input: {
                    scale: checkin.rating,
                    ratingCheckinId: checkin.id,
                    ratingUserId: userId
                }
            }))
            .then((response) => {
                // we update the checkin too
                API.graphql(graphqlOperation(`mutation UpdateCheckin($input: UpdateCheckinInput!) {
                    updateCheckin(input: $input) {
                      id
                      entered
                      left
                      rating {
                          id
                          scale
                          user {
                              id
                          }
                      }
                  }
                }
                  `, {
                        input: {
                            id: checkin.id,
                            checkinRatingId: response.data.createRating.id,
                            lastUpdate: when
                        }
                    }))
                    .then((response2) => {
                        // we update the checkin too
                        onSaveCheckinRating({ ...response.data.createRating });
                    }).catch(error => {
                        console.log("Error updating the rating", error);
                    });

                onSaveCheckinRating({ ...response.data.createRating });
                createActionStreamForUsers(userId, getCheckinRecipientsForActionStream(checkin, userId), response.data.updateCheckin, AS_CHECKIN_UPDATE);

            }).catch(error => {
                console.log("Error creating the rating", error);
            });
    } else {
        // we update the rating
        API.graphql(graphqlOperation(`mutation UpdateRating($input: UpdateRatingInput!) {
            updateRating(input: $input) {
              id
              scale
            }
          }
          `, {
                input: {
                    scale: rating,
                    id: checkin.id,
                    ratingUserId: userId
                }
            }))
            .then((response) => {
                onSaveCheckinRating({ ...response.data.updateRating });
                console.log("TODO: Update rating was not implemented using Active Stream");

            }).catch(error => {
                console.log("Error updating the rating", error);
            });
    }
}

export const updateLastUpdate = (checkinId, when) => {
    API.graphql(graphqlOperation(`mutation UpdateCheckin($input: UpdateCheckinInput!) {
        updateCheckin(input: $input) {
          id
      }
    }
      `, {
            input: {
                id: checkinId,
                lastUpdate: when
            }
        }))
        .then((response2) => {
        }).catch(error => {
            console.log("Error updating the updateLastUpdate", error);
        });
}

export const postComment = (comment, checkin, user, onCommentPostedFn, initStartedFn, initCompleteFn) => {

    initStartedFn();
    const when = '' + (new Date()).getTime();
    const theInput = {
        id: MakeId(12),
        content: comment,
        date: when,
        targetCheckinId: checkin.id,
        commentCheckinId: checkin.id,
        commentUserId: user.id
    };

    const theInputPostComment = {
        ...theInput, user: {
            id: user.id,
            username: user.username
        },
        comments: {
            items: []
        }
    }

    onCommentPostedFn(theInputPostComment);

    document.getElementById('comment-input' + checkin.id).value = '';

    API.graphql(graphqlOperation(`mutation CreateComment($input: CreateCommentInput!) {
      createComment(input: $input) {
        id
        date
        content
        targetCheckinId
        user {
          id
          username
        }
      }
    }
    `, {
            input: theInput
        })).then(response => {
            createActionStreamForUsers(user.id, getCheckinRecipientsForActionStream(checkin, user.id), response.data.createComment, AS_COMMENT_NEW);
            updateLastUpdate(checkin.id, when);
            initCompleteFn();
        }).catch(error => {
            console.log("post comment error", error);
            document.getElementById('comment-input' + checkin.id).value = theInput.content;
            initCompleteFn();
        });

}

export const newCheckin = (home, user, onCheckinCreatedFn, checkinCompletedFn) => {
    const newCheckinId = MakeId(12);
    const when = '' + (new Date()).getTime();
    const theInput = {
        id: newCheckinId,
        checkinHomeId: home.id,
        targetHomeId: home.id,
        checkinUserId: user.id,
        authorId: user.id,
        entered: when,
        lastUpdate: when,
        left: LEFT_HOME_DEFAULT
    };

    onCheckinCreatedFn(
        {
            ...theInput,
            home: home,
            user: user,
            comments: {
                items: []
            }
        }
    );

    // TODO cover the case where the user is still checked in somewhere
    // but for whatever reason we are checking him in another place
    API.graphql(graphqlOperation(createCheckinQL, {
        input: theInput
    })).then(response => {
        API.graphql(graphqlOperation(updateActiveCheckinQL, {
            input: {
                id: user.id,
                activecheckin: response.data.createCheckin.id,
                activehomeid: home.id
            }
        })).then(response2 => {
            createActionStreamForUsers(user.id, 
                getLandlordRecipientsForActionStream(home, user.id), 
                response.data.createCheckin, 
                AS_CHECKIN_NEW);
            checkinCompletedFn();
        }).catch(error => {
            console.log("post checkin error", error);
        });

    }).catch(error => {
        console.log("postUpdate error", error);
    });
}

export const newCheckout = (user, checkin, checkoutActionStreamRecipients, checkoutCompletedFn, onCheckoutCreatedFn) => {
    const when = '' + (new Date()).getTime();

    const originalActivecheckin = user.activecheckin;

    API.graphql(graphqlOperation(updateActiveCheckinQL, {
        input: {
            id: user.id,
            activecheckin: NO_ACTIVE_CHECKIN,
            activehomeid: NO_ACTIVE_HOME,
        }
    })).then(response2 => {

        onCheckoutCreatedFn({ id: originalActivecheckin, left: when });

        API.graphql(graphqlOperation(updateCheckinQL, {
            input: {
                id: originalActivecheckin,
                left: when
            }
        })).then((response) => {
            checkoutCompletedFn();

            if (checkin !== null) {
                createActionStreamForUsers(user.id, checkoutActionStreamRecipients, response.data.updateCheckin, AS_CHECKIN_UPDATE);
            } else {
                console.log("newcheckout received null for checkin");
            }


        }).catch(error => {
            checkoutCompletedFn();
            console.log("Note: original checkin might have been deleted", error);
        });

    }).catch(error => {
        console.log("Can't reset the user active location", error);
    });
}

export const deleteCheckinExecute = (checkin, user, onCheckinDeleteClickedFn) => {
    onCheckinDeleteClickedFn({ id: checkin.id });
    API.graphql(graphqlOperation(`mutation DeleteCheckin($input: DeleteCheckinInput!) {
      deleteCheckin(input: $input) {
        id
        targetHomeId
      }
    }
    `, {
            input: {
                id: checkin.id
            }
        })).then(response => {
            createActionStreamForUsers(user.id, getLandlordRecipientsForActionStream(checkin.home, user.id), response.data.deleteCheckin, AS_CHECKIN_DELETE);
        }).catch(error => {
            console.log("DeleteCheckin error", error);
        });
}

export const getCheckin = (checkinId, onResponseFn) => {
    API.graphql(graphqlOperation(getCheckinQL,
        {
            id: checkinId
        }))
        .then((response) => {
            onResponseFn(response.data.getCheckin);
        }).catch(error => {
            console.log("getCheckin error", error);
        });
}

export const getMoreCheckins = (homeId, limit, nextToken, onResponseFn) => {
    API.graphql(graphqlOperation(getMoreCheckinsForHome,
        {
            id: homeId,
            nextToken: nextToken,
            limit: limit
        }))
        .then((response) => {
            onResponseFn(response.data.getHome);
        }).catch(error => {
            console.log("initializeUiForUser error", error);
        });
}

export const getMoreCheckinsPlace = (userId, homeId, limit, nextToken, onResponseFn) => {
    API.graphql(graphqlOperation(getMoreCheckinsForPlace,
        {
            id: homeId,
            authorId: userId,
            nextToken: nextToken,
            limit: limit
        }))
        .then((response) => {
            onResponseFn(response.data.getHome);
        }).catch(error => {
            console.log("initializeUiForUser error", error);
        });
}