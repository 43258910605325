import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { withAuthenticator } from 'aws-amplify-react';
import Amplify, { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import aws_exports from './aws-exports';
import Loadable from 'react-loadable';
import { fetchUserAppData } from './utils/graphQL/userQL.js';
import { INIT_USERNAME, USER_FETCH_DATA, UI_LOADING_COMPLETE, UI_LOADING, ON_CHECKIN_DELETE_RECEIVED, USER_UPDATE_VIEW_MODE, AS_CHECKIN_NEW_COMMENT, AS_CHECKIN_UPDATE, AS_CHECKIN_NEW, AS_CHECKIN_DELETE } from './store/actions';
import DefaultLayoutMaxidomo from './containers/DefaultLayoutMaxidomo/DefaultLayoutMaxidomo';
import './App.scss';
import './aws-config.js';
import { getCheckin } from './utils/graphQL/checkinQL';

Amplify.configure(aws_exports);

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

class App extends Component {

  signOut = () => {
    Auth.signOut()
      .then(
        data => {
          this.props.rerender();
        }).catch(
          err => console.log('Sign out error', err)
        );
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then(user => {
      this.props.onReceivedUsernameFromCognito(user);
      this.initializeAppInterface();
    })
      .catch(err => console.log(err));
  }

  onHomeAdded = (home) => {
    console.log("remove onHomeAdded!");
  }

  initializeAppInterface = () => {
    fetchUserAppData(
      this.props.initStarted,
      this.props.initComplete,
      this.props.user,
      this.props.setInterfaceOwnerView,
      this.props.initializeHomesAndPlaces,
      this.handleNewCommentReceivedFromActiveStream,
      this.props.onActiveStreamCheckinUpdate,
      this.props.onActiveStreamCheckinNew,
      this.props.onActiveStreamCheckinDelete);
  }

  handleNewCommentReceivedFromActiveStream = (deltaSyncPayload) => {
    if (this.props.checkinsIdsInStore.indexOf(deltaSyncPayload.targetCheckinId) !== -1) {
      this.props.onActiveStreamCommentReceived(deltaSyncPayload);
    } else {
      getCheckin(deltaSyncPayload.targetCheckinId, this.props.onActiveStreamCheckinNew);
    }
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route
            path="/"
            name="Home"
            render={routeProps => {
              return (
                <DefaultLayoutMaxidomo
                  signOut={this.signOut}
                  onHomeAdded={(res) => this.onHomeAdded(res)}
                  {...routeProps} />
              )
            }}
          />
        </Switch>
      </Router>
    );
  }
}

export default props => {
  const mapStateToProps = (state) => {
    return {
      user: state.user,
      checkinsIdsInStore: state.checkins.map(checkin => checkin.id)
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      setInterfaceOwnerView: (value) => {
        dispatch({ type: USER_UPDATE_VIEW_MODE, value: value })
      },

      onReceivedUsernameFromCognito: (userNameAndId) => {
        dispatch({ type: INIT_USERNAME, value: userNameAndId })
      },

      onActiveStreamCheckinUpdate: (value) => {
        dispatch({ type: AS_CHECKIN_UPDATE, value: value })
      },

      onActiveStreamCheckinNew: (value) => {
        dispatch({ type: AS_CHECKIN_NEW, value: value })
      },

      onActiveStreamCheckinDelete: (value) => {
        dispatch({ type: AS_CHECKIN_DELETE, value: value })
      },

      onActiveStreamCommentReceived: (value) => {
        dispatch({ type: AS_CHECKIN_NEW_COMMENT, value: value })
      },

      initializeHomesAndPlaces: (value) => {
        dispatch({ type: USER_FETCH_DATA, value: value })
      },

      onCheckinDeleteReceived: (checkinInfo) => {
        dispatch({ type: ON_CHECKIN_DELETE_RECEIVED, value: checkinInfo })
      },

      initComplete: () => {
        dispatch({ type: UI_LOADING_COMPLETE })
      },

      initStarted: () => {
        dispatch({ type: UI_LOADING })
      }
    }
  }

  const AppComponent = connect(mapStateToProps, mapDispatchToProps)(withAuthenticator(App));
  return <AppComponent {...props} />
}