export const consolidateVisitorsAndLandloardsImmutably = (theHomesList, visitorsOrLandlords = 'visitors') => {

    // takes the getUsers array and produces a list of visitors with all the places
    // they can access
    // input: action.value.homes for theHomesList

    const placesOrHomes = (visitorsOrLandlords === 'visitors' ? 'places' : 'homes');
    const visitorOrLandlord = (visitorsOrLandlords === 'visitors' ? 'visitor' : 'landlord');

    const homeListCopy = JSON.parse(JSON.stringify(theHomesList.items));

    const flatListOfVisitorHomes = [].concat(...homeListCopy.map(theHome => {
        return { home: theHome.home, thePeople: theHome.home[visitorsOrLandlords].items };
    }).filter(theRecord => theRecord.thePeople !== null && theRecord.thePeople[0] !== undefined)
        .map(thisHomeVisitorRecord => {
            return thisHomeVisitorRecord.thePeople.map(thisPeople => {
                let theResult = { ...thisPeople[visitorOrLandlord] };
                theResult[placesOrHomes] = [thisHomeVisitorRecord.home];
                return theResult;
            })
        }));

    let consolidatedVisitors = [];
    let personIdArray = [];
    let index = 0;
    for (const personHomeSingleRecord of flatListOfVisitorHomes) {
        if (personIdArray.hasOwnProperty(personHomeSingleRecord.id)) {
            const theIndex = personIdArray[personHomeSingleRecord.id];

            let toPush = { ...personHomeSingleRecord[placesOrHomes][0] };
            delete toPush.visitors;
            delete toPush.landlords;

            consolidatedVisitors[theIndex][placesOrHomes].push(toPush);
        } else {
            delete personHomeSingleRecord[placesOrHomes][0].visitors;
            delete personHomeSingleRecord[placesOrHomes][0].landlords;

            consolidatedVisitors[index] = {};
            consolidatedVisitors[index][placesOrHomes] = personHomeSingleRecord[placesOrHomes];
            consolidatedVisitors[index].user = {
                id: personHomeSingleRecord.id,
                username: personHomeSingleRecord.username,
            }
            personIdArray[personHomeSingleRecord.id] = index;
            index++;
        }
    }

    return consolidatedVisitors;
}

export const consolidateContacts = (homes, places) => {
    let ids = [];
    let results = [];
    if (homes.items !== undefined) {
        for (const theHome of homes.items) {
            for (const theContact of theHome.home.visitors.items) {
                if (ids.indexOf(theContact.visitor.id) === -1) {
                    results.push(theContact.visitor);
                    ids.push(theContact.visitor.id);
                }
            }
            for (const theContact of theHome.home.landlords.items) {
                if (ids.indexOf(theContact.landlord.id) === -1) {
                    results.push(theContact.landlord);
                    ids.push(theContact.landlord.id);
                }
            }
        }
        for (const theHome of places.items) {
            for (const theContact of theHome.home.visitors.items) {
                if (ids.indexOf(theContact.visitor.id) === -1) {
                    results.push(theContact.visitor);
                    ids.push(theContact.visitor.id);
                }
            }
            for (const theContact of theHome.home.landlords.items) {
                if (ids.indexOf(theContact.landlord.id) === -1) {
                    results.push(theContact.landlord);
                    ids.push(theContact.landlord.id);
                }
            }
        }
    }
    return results;
}