import './actions';
import {
    POST_COMMENT,
    ON_HOME_UPDATE_IMAGE,
    INIT_USERNAME,
    USER_FETCH_DATA,
    ON_CHECKIN_DELETE_RECEIVED,
    ON_CHECKIN_DELETE_CLICKED,
    ON_HOME_DELETE,
    UI_LOADING,
    UI_LOADING_COMPLETE,
    ON_CHECKOUT,
    ADDED_HOME_BY_CODE,
    ADDED_HOME_BY_ADDRESS,
    ON_NEW_CHECKIN_CLICKED,
    CLOSE_MODAL,
    OPEN_MODAL,
    TOGGLE_MODAL,
    USER_UPDATE_VIEW_MODE,
    UPDATE_CHECKIN,
    UPDATE_CHECKIN_RATING_MANUALLY,
    ON_MORE_CHECKIN_RECEIVED,
    ON_DISMISS_ALERT_MESSAGE,
    ON_SHOW_ALERT_MESSAGE,
    ON_HOME_UNSUBSCRIBE,
    AS_CHECKIN_NEW_COMMENT,
    AS_CHECKIN_UPDATE,
    AS_CHECKIN_NEW,
    AS_CHECKIN_DELETE
} from './actions';
import { ExtractHomeIndexFromHomeId } from '../utils/helper.js';
import { NO_ACTIVE_HOME, NO_ACTIVE_CHECKIN } from '../constants';
import { consolidateContacts } from './reducerutils';

export const initialState = {
    user: {
        username: 'anonymous',
        id: 'unavailable',
        homeIds: [],
        placeIds: [],
        phonenumber: [],
        email: [],
        activehomeid: NO_ACTIVE_HOME,
        activecheckin: NO_ACTIVE_CHECKIN,
        ownerview: 'visitor'
    },
    checkins: [],
    homes: [],
    places: [],
    users: [],
    ui: {
        modal: {
            open: false,
            title: 'A modal',
            type: 'UNKNOWN'
        },
        alertmessage: {
            open: false,
            text: 'Welcome to Maxidomo'
        },
        ajax: {
            loading: false,
            loadingThreads: 0,
        }
    }
}

const reducer = (state = initialState, action) => {
    let newState;
    switch (action.type) {
        case UI_LOADING:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    ajax: {
                        loadingThreads: state.loadingThreads + 1,
                        loading: (state.loadingThreads + 1 > 0 ? true : false)
                    }
                }
            };
        case UI_LOADING_COMPLETE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    ajax: {
                        loadingThreads: (state.loadingThreads - 1 < 0 ? 0 : state.loadingThreads - 1),
                        loading: (state.loadingThreads - 1 > 0 ? true : false)
                    }
                }
            };
        case INIT_USERNAME:
            return {
                ...state,
                user: {
                    username: action.value.username,
                    id: action.value.attributes.sub,
                    phonenumber: [action.value.attributes.phone_number],
                    email: [action.value.attributes.email]
                }
            }
        case USER_FETCH_DATA:
            newState = { ...state };
            let checkins = [];

            newState.user.homeIds = [];
            newState.user.placeIds = [];

            const homeItems = action.value.homes.items.map(theHome => {
                return { ...theHome, home: { ...theHome.home, userIsOwnerOfHome: true } };
            });

            const placeItems = action.value.places.items.map(theHome => {
                return { ...theHome, home: { ...theHome.home, userIsOwnerOfHome: false } };
            });

            for (const thisHome of homeItems) {
                if (thisHome.hasOwnProperty('home')) {
                    if (thisHome.home.hasOwnProperty('checkins')) {
                        for (const thisHouseCheckinsList of thisHome.home.checkins.items) {
                            checkins.push({ ...thisHouseCheckinsList, targetHomeId: thisHome.home.id, userIsOwnerOfHome: true });
                        }
                    }
                    newState.user.homeIds.push(thisHome.home.id);
                }
            }

            for (const thisHome of placeItems) {
                if (thisHome.hasOwnProperty('home')) {
                    if (thisHome.home.hasOwnProperty('checkins')) {
                        for (const thisHouseCheckinsList of thisHome.home.checkins.items) {
                            checkins.push({ ...thisHouseCheckinsList, targetHomeId: thisHome.home.id, userIsOwnerOfHome: false });
                        }
                    }
                    newState.user.placeIds.push(thisHome.home.id);
                }
            }

            checkins.sort((checkinA, checkinB) => {
                return (parseInt(checkinB.entered) - parseInt(checkinA.entered));
            })

            const homes = action.value.homes.items.map(theHome => {
                return { ...theHome.home, userIsOwnerOfHome: true };
            });

            const places = action.value.places.items.map(theHome => {
                return { ...theHome.home, userIsOwnerOfHome: false };
            });

            return {
                ...newState,
                user: {
                    ...state.user,
                    homeIds: newState.user.homeIds,
                    placeIds: newState.user.placeIds,
                    activecheckin: action.value.activecheckin,
                    activehomeid: action.value.activehomeid
                },
                homes: homes,
                places: places,
                checkins: checkins,
                users: consolidateContacts(action.value.homes, action.value.places)
            };
        case USER_UPDATE_VIEW_MODE:
            return { ...state, user: { ...state.user, ownerview: action.value } }
        case ON_NEW_CHECKIN_CLICKED:
        case AS_CHECKIN_NEW:
            newState = {
                ...state,
                user: {
                    ...state.user,
                    activecheckin: (state.user.id !== action.value.user.id) ? state.user.activecheckin : action.value.id,
                    activehomeid: (state.user.id !== action.value.user.id) ? state.user.activehomeid : action.value.targetHomeId
                }
            };

            const existingCheckinId = state.checkins.findIndex((theCheckinLocal) => {
                return theCheckinLocal.id === action.value.id;
            });

            if (!(existingCheckinId >= 0)) {
                newState.checkins.unshift({
                    ...action.value,
                    comments: {
                        items: (action.value.comments !== undefined && action.value.comments.items !== undefined) ? action.value.comments.items : []
                    }
                });
            }
            return newState;
        case ON_MORE_CHECKIN_RECEIVED:
            return {
                ...state,
                checkins: state.checkins.concat(action.value)
            }
        case AS_CHECKIN_UPDATE:
        case UPDATE_CHECKIN:

            let activecheckin = state.user.activecheckin;
            let activehomeid = state.user.activehomeid;

            const checkinToModify = state.checkins.filter(theCheckinLocal => theCheckinLocal.id === action.value.id)[0];
            if (typeof action.value.left !== undefined && action.value.left.length > 8 && checkinToModify.left !== action.value.left) {
                activecheckin = NO_ACTIVE_CHECKIN;
                activehomeid = NO_ACTIVE_HOME;
            }

            const updatedCheckinList = state.checkins.map((theCheckinLocal) => {
                if (theCheckinLocal.id === action.value.id) {
                    return {
                        ...theCheckinLocal,
                        left: action.value.left,
                        rating: action.value.rating
                    }
                }
                return theCheckinLocal;
            });

            return {
                ...state, user: {
                    ...state.user,
                    activecheckin: activecheckin,
                    activehomeid: activehomeid
                }, checkins: updatedCheckinList
            };
        case UPDATE_CHECKIN_RATING_MANUALLY:
            return {
                ...state, checkins: state.checkins.map((theCheckinLocal) => {
                    if (theCheckinLocal.rating.id === action.value.id) {
                        return {
                            ...theCheckinLocal,
                            rating: {
                                id: action.value.id,
                                scale: action.value.scale
                            }
                        }
                    }
                    return theCheckinLocal;
                })
            };
        case ON_CHECKOUT:
            newState = { ...state };
            const checkinToUpdateOnCheckout = newState.checkins.findIndex(function (theCheckin) {
                return theCheckin.id === action.value.id;
            });

            if (typeof newState.checkins[checkinToUpdateOnCheckout] !== "undefined") {
                newState.checkins[checkinToUpdateOnCheckout].left = action.value.left;
            }

            return {
                ...newState, user: {
                    ...state.user,
                    activecheckin: NO_ACTIVE_CHECKIN,
                    activehomeid: NO_ACTIVE_HOME
                }
            };

        case AS_CHECKIN_DELETE:
        case ON_HOME_DELETE:
            return {
                ...state,
                user: {
                    ...state.user,
                    homes: [
                        ...state.user.homes.filter(theHome => theHome.id !== action.value.id)
                    ]
                }
            };
        case ON_HOME_UNSUBSCRIBE:
            return {
                ...state,
                user: {
                    ...state.user,
                    places: [
                        ...state.user.places.filter(theHome => theHome.id !== action.value.id)
                    ]
                }
            };
        case ADDED_HOME_BY_CODE:
            action.value.userIsOwnerOfHome = false;
            return {
                ...state,
                user: {
                    ...state.user,
                    places: (state.user.places === undefined) ? [action.value]
                        : state.user.places.concat(action.value)
                }
            };
        case ADDED_HOME_BY_ADDRESS:
            action.value.userIsOwnerOfHome = true;
            return {
                ...state,
                user: {
                    ...state.user,
                    homes: (state.user.homes === undefined) ? [action.value]
                        : state.user.homes.concat(action.value)
                }
            };
        case ON_HOME_UPDATE_IMAGE:
            newState = { ...state };
            const homeSelection = ExtractHomeIndexFromHomeId(newState.user.homes, action.value.imageHomeId);
            if (newState.user.homes[homeSelection].images.hasOwnProperty('items') === null ||
                typeof newState.user.homes[homeSelection].images[0] === "undefined"
            ) {
                newState.user.homes[homeSelection].images.items = [];
            }
            newState.user.homes[homeSelection].images.items[0] = { id: action.value.id, uri: action.value.uri };

            return newState;
        case ON_CHECKIN_DELETE_RECEIVED:
        case ON_CHECKIN_DELETE_CLICKED:
            return {
                ...state,
                checkins: state.checkins.filter(theCheckin => theCheckin.id !== action.value.id)
            };

        case ON_SHOW_ALERT_MESSAGE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    alertmessage: {
                        ...state.ui.alertmessage,
                        open: true,
                        text: action.value.text
                    }
                }
            };
        case ON_DISMISS_ALERT_MESSAGE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    alertmessage: {
                        ...state.ui.alertmessage,
                        open: false,
                    }
                }
            };
        case OPEN_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: true,
                        type: action.value.type,
                        title: action.value.title,
                        parameters: action.value.parameters
                    }
                }
            };
        case CLOSE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: false,
                        type: 'UNKNOWN',
                        title: 'A modal'
                    }
                }
            };

        case TOGGLE_MODAL:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    modal: {
                        ...state.ui.modal,
                        open: !state.ui.modal.open
                    }
                }
            };

        case AS_CHECKIN_NEW_COMMENT:

            return {
                ...state, checkins: state.checkins.map(function (theCheckin) {
                    if (theCheckin.id === action.value.targetCheckinId) {
                        theCheckin.comments.items.push(action.value);
                    }
                    return theCheckin;
                })
            }

        case POST_COMMENT:

            return {
                ...state, checkins: state.checkins.map(function (theCheckin) {
                    if (theCheckin.id === action.value.commentCheckinId) {
                        theCheckin.comments.items.push(action.value);
                    }
                    return theCheckin;
                })
            }

        default:
    }

    return state;
}

export default reducer;
