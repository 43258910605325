import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import CheckinPoster from '../CheckinPoster/CheckinPoster';
import CheckoutPoster from '../CheckoutPoster/CheckoutPoster';
import { CLOSE_MODAL, OPEN_MODAL, TOGGLE_MODAL } from '../../../store/actions';
import HouseCodeEntry from '../../HomeSettings/HouseCodeEntry/HouseCodeEntry';
import HouseAddressEntry from '../../HomeSettings/HouseAddressEntry/HouseAddressEntry';

class CheckinModal extends Component {

    openModal = () => {
        this.props.onModalOpen();
    }

    closeModal = () => {
        this.props.onModalClose();
    }

    toggleModal = () => {
        this.props.onModalToggle();
    }

    render = () => {

        let modalContentBlock;

        switch (this.props.modal.type) {
            case 'CHECKIN':
                modalContentBlock = (this.props.homes.length + this.props.places.length === 0 ? null : <CheckinPoster
                    postedCheckin={this.closeModal}></CheckinPoster>);
                break;
            case 'CHECKOUT':
                modalContentBlock = (this.props.homes.length + this.props.places.length === 0 ? null : <CheckoutPoster
                    postedCheckin={this.closeModal}></CheckoutPoster>);
                break;
            case 'ADD_BY_CODE':
                modalContentBlock = <HouseCodeEntry
                    onHomeAdded={(res) => this.props.onHomeAddedCode(res)}
                    onModalClose={this.closeModal}
                />;
                break;
            case 'ADD_BY_ADDRESS':
                modalContentBlock = <HouseAddressEntry
                    onModalClose={this.closeModal}
                />
                break;
            case 'INVITE_TO_HOME':
                modalContentBlock = (<><p>
                    <strong>Step 1</strong>&nbsp;Share the code
                    &nbsp;<strong>{this.props.modal.parameters.code}</strong>&nbsp;
                    with other Maxidomo users.</p>
                    <p>
                        <strong>Step 2</strong>&nbsp;After the user added the code, you will be able to manage permissions on this page.
                    </p></>)
                break;
            default:
                break;
        }

        return (<Modal
            isOpen={this.props.modal.open}
            toggle={this.toggleModal}
            className={'modal-lg ' + this.props.className}>
            <ModalHeader toggle={this.toggleModal}>{this.props.modal.title}</ModalHeader>
            <ModalBody>
                {modalContentBlock}
            </ModalBody>
        </Modal>)
    }
}

CheckinModal.propCheckinModalTypes = {
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired
    }),
    closeModal: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {

    return {
        user: state.user,
        homes: state.homes,
        places: state.places,
        modal: state.ui.modal
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onHomeAdded: () => dispatch({ type: 'HOME_ADDED_FROM_CODE' }),
        onModalOpen: () => dispatch({ type: OPEN_MODAL }),
        onModalClose: () => dispatch({ type: CLOSE_MODAL }),
        onModalToggle: () => dispatch({ type: TOGGLE_MODAL })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinModal);