import { API, graphqlOperation } from 'aws-amplify';
import { MakeId, scrollToTop } from '../helper.js';

export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
        id
        username
        phonenumber
        email
      }
    }`;

export const deleteHomeLandlordQL = `mutation DeleteHomeLandlord($input: DeleteHomeLandlordInput!) {
      deleteHomeLandlord(input: $input) {
          home {
              id
          }
        }
      }`;

export const deleteHomeVisitorQL = `mutation DeleteHomeVisitor($input: DeleteHomeVisitorInput!) {
        deleteHomeVisitor(input: $input) {
            home {
                id
            }
          }
        }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              entered
              left
              rating {
                id
                scale
                feedback
                user {
                  id
                  username
                }
              }
            }
          }
          `;

export const saveAddress = (user, address, uri, onSaveAddressCallback) => {

  const newHomeId = MakeId();
  const homeLandlordId = MakeId(8);

  API.graphql(graphqlOperation(`mutation CreateHome($input: CreateHomeInput!) {
            createHome(input: $input) {
              id
              address
            }
          }
          `, {
      input: {
        id: newHomeId,
        address: address,
      }
    }))
    .then((response) => {
      if (uri !== null) {
        API.graphql(graphqlOperation(`mutation CreateImage($input: CreateImageInput!) {
                        createImage(input: $input) {
                          id
                          uri
                        }
                      }
                      `, {
            input: {
              imageUserId: user.id,
              imageHomeId: newHomeId,
              uri: uri
            }
          })).then((response2) => {
            const newHome = {
              ...response.data.createHome,
              images: { items: [{ uri: response2.data.createImage.uri }] },
              landlords: { items: [{ id: homeLandlordId, landlord: user }] },
              visitors: { items: [] }
            };
            onSaveAddressCallback(newHome);
            scrollToTop();
          })
          .catch(error => {
            console.log("Error creating the image for this home", error);
          });
      } else {
        const newHome = {
          ...response.data.createHome,
          images: { items: [] },
          landlords: { items: [{ id: homeLandlordId, landlord: user }] },
          visitors: { items: [] }
        };
        onSaveAddressCallback(newHome);
        scrollToTop();
      }

      API.graphql(graphqlOperation(`mutation CreateHomeLandlord($input: CreateHomeLandlordInput!) {
                    createHomeLandlord(input: $input) {
                      id
                    }
                  }
                  `, {
          input: {
            id: homeLandlordId,
            homeLandlordHomeId: newHomeId,
            homeLandlordLandlordId: user.id
          }
        }))
        .catch(error => {
          console.log("Error updating the homeLandlord", error);
        });


    }).catch(error => {
      console.log("Error updating the address for the user", error);
    });
}

export const saveCode = (user, code, onSaveCodeCallback) => {
  const homeLandlordId = MakeId(8);
  API.graphql(graphqlOperation(`mutation CreateHomeLandlord($input: CreateHomeLandlordInput!) {
        createHomeLandlord(input: $input) {
          id
          home {
            id
            address
            images {
                items {
                    uri
                }
            }
            landlords {
                items {
                  id 
                  landlord {
                    username
                  }
               }
            }
            visitors {
              items {
                id 
                visitor {
                  id
                  username
                }
             }
            }
          }
          landlord {
            id
            username
            name
            email
            phonenumber
          }
        }
      }
      `, {
      input: {
        id: homeLandlordId,
        homeLandlordHomeId: code,
        homeLandlordLandlordId: user.id
      }
    }))
    .then((response) => {
      onSaveCodeCallback(response.data.createHomeLandlord.home);
      scrollToTop();
    }).catch(error => {
      console.log("Error updating the code the user", error);
    });
}

export const saveCodeForVisitors = (user, code, onSaveCodeCallback) => {
  // First we create permission
  const createPermissions = `mutation CreatePermissions($input: CreatePermissionsInput!) {
        createPermissions(input: $input) {
          id
        }
      }
      `;

  /*
  input CreatePermissionsInput {
    id: ID
    profile: VisitorType!
    issued: String!
    expires: String
    permissionsHomevisitorId: ID
  }*/

  /*input CreateHomeVisitorInput {
  id: ID
  homeVisitorHomeId: ID!
  homeVisitorVisitorId: ID!
  homeVisitorPermissionsId: ID!
}*/

  API.graphql(graphqlOperation(createPermissions, {
    input: {
      profile: 'contractor',
      issued: (new Date()).getTime()
    }
  }))
    .then((response) => {
      const permissionId = response.data.createPermissions.id;

      API.graphql(graphqlOperation(`mutation CreateHomeVisitor($input: CreateHomeVisitorInput!) {
                createHomeVisitor(input: $input) {
                  id
                  home {
                    id
                    address
                    images {
                      items {
                          uri
                      }
                    }
                    visitors {
                      items {
                        id 
                        visitor {
                          username
                          id
                        }
                      }
                    }
                    landlords {
                      items {
                        id 
                        landlord {
                          id
                          username
                        }
                      }
                    }
                  }
                  visitor {
                    id
                    username
                  }
                }
              }`, {
          input: {
            homeVisitorHomeId: code,
            homeVisitorVisitorId: user.id,
            homeVisitorPermissionsId: permissionId
          }
        }))
        .then((response2) => {
          onSaveCodeCallback(response2.data.createHomeVisitor.home);
          scrollToTop();
        }).catch(error => {
          console.log("Error updating the code the user", error);
        });


    }).catch(error => {
      console.log("Error updating the code the user", error);
    });
}

export const uploadNewImageForHome = (uri, userId, homeId, onUpdateImageFn, successFn) => {
  // new image
  API.graphql(graphqlOperation(createImageQL, {
    input: {
      imageUserId: userId,
      imageHomeId: homeId,
      uri: uri
    }
  }))
    .then((response) => {
      onUpdateImageFn({
        imageHomeId: homeId,
        id: response.data.createImage.id,
        uri: uri
      });
      successFn(uri);
    });
}

export const updateImageForHome = (uri, imageId, userId, homeId, onUpdateImageFn, successFn) => {
  API.graphql(graphqlOperation(updateImageQL, {
    input: {
      id: imageId,
      imageUserId: userId,
      imageHomeId: homeId,
      uri: uri
    }
  }))
    .then((response) => {
      onUpdateImageFn({
        imageHomeId: homeId,
        uri: uri,
        id: imageId,
      });
      successFn(uri);
    });
}

export const deleteLandlords = (theLandlords, onDeleteHomeFn) => {
  if (theLandlords.items !== null) {
    for (const theLandlord of theLandlords.items) {
      if (theLandlord === null) {
        console.log("Cannot DeleteHomeLandlord - the landlordHomeId is null");
      } else {
        API.graphql(graphqlOperation(deleteHomeLandlordQL, {
          input: {
            id: theLandlord.id
          }
        }))
          .then((response) => {
            // issue: homelandlord points to home that no longer exists!
            API.graphql(graphqlOperation(deleteHomeQL, {
              input: {
                id: response.data.deleteHomeLandlord.home.id
              }
            }))
              .then((responseDelete) => {
                if (responseDelete.data.deleteHome !== null) {
                  onDeleteHomeFn({
                    id: responseDelete.data.deleteHome.id
                  });
                }
              }).catch(error => {
                console.log("Error running DeleteHome", error);
              });

          }).catch(error => {
            console.log("Error DeleteHomeLandlord", error);
          });
      }
    }
  }
}

export const deleteHomeVisitor = (theHomeVisitorId, onDeleteVisitorFn) => {
  API.graphql(graphqlOperation(deleteHomeVisitorQL, {
    input: {
      id: theHomeVisitorId
    }
  })).then((response) => {
    onDeleteVisitorFn({
      id: response.data.deleteHomeVisitor.home.id
    });
  }).catch(error => {
    console.log("Error deleteVisitor", error);
  });
}

