import React, { Component } from 'react';
import { MakeId } from '../../utils/helper';
import { Storage } from 'aws-amplify';
import ImageUploader from 'react-images-upload';

import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import PropTypes from 'prop-types';

class S3ImageUpload extends Component {

    constructor(props) {
        super(props);
        this.state = { pictures: [], uploading: false };
        this.onDrop = this.onDrop.bind(this);
    }


    onDrop = (picture) => {

        const file = picture[0];
        const fileName = "home-" + MakeId(16, true) + ".png";

        Auth.currentCredentials()
            .then(credentials => {

                if (this.state.uploading === false) {
                    this.setState({ uploading: true });

                    AWS.config.update({
                        credentials: credentials
                    });

                    let uri = this.props.destinationFolder + fileName;
                    Storage.put(
                        uri,
                        file,
                        { level: 'public' }
                    ).then(data => {
                        this.setState({ uploading: false });
                        this.props.onUploaded(uri);
                    }).catch(err => {
                        console.log('Error uploading a photo', err);
                        this.setState({ uploading: false });
                    })
                }
            }).catch(err => {
                console.log('Error uploading a photo - credentials missing', err);
                this.setState({ uploading: false });
            })
    }

    render() {
        return (
            <ImageUploader
                withIcon={true}
                buttonText='Choose photo of this house'
                onChange={this.onDrop}
                imgExtension={['.jpg', '.gif', '.png', '.gif']}
                maxFileSize={5242880}
                singleImage={true}
                withPreview={true}
            />
        );
    }
}

S3ImageUpload.propTypes = {
    destinationFolder: PropTypes.string.isRequired,
    onUploaded: PropTypes.func.isRequired
};

export default S3ImageUpload; 