import { API, graphqlOperation } from 'aws-amplify';
import { NO_ACTIVE_CHECKIN, NO_ACTIVE_HOME } from '../../constants';
import { subscribeToActionStream } from './actionStreamQL';

const LIMIT_CHECKINS = 10;

export const createUserQL = `mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
        id
        username
        phonenumber
        email
      }
    }`;

export const getUserQL = `query GetUser($id: ID!, $authorId: String) {
      getUser(id: $id) {
        id
        username
        phonenumber
        email
        activecheckin
        activehomeid
        ownerview
        places {
          items {
            home 
            {
              id
              address
              images {
                items {
                  uri
                  id
                }
              }
              landlords {
                items {
                  id 
                  landlord {
                    id
                    username
                    phonenumber
                  }
                }
              }
              visitors {
                items {
                  id
                  visitor {
                    id
                    username
                    phonenumber
                  }
                }
                nextToken
              }
              checkins (
                  limit: ` + LIMIT_CHECKINS + `
                  sortDirection: DESC
                  filter: {
                    authorId: {
                      eq: $authorId
                    }
                  }
              ) {
                  nextToken
                  items {
                    id 
                    entered
                    left
                    user {
                        id
                        username
                        activecheckin
                    }
                    rating {
                      id
                      scale
                    }
                    comments (sortDirection: ASC) {
                      items {
                        id
                        content
                        date
                        user {
                          id
                          username
                        }
                      }
                    }
                  }
              }
            }
          }
          nextToken
        }
        homes {
          items {
            home {
              id
              address
              images {
                items {
                  uri
                  id
                }
              }
              checkins (
                  limit: ` + LIMIT_CHECKINS + `
                  sortDirection: DESC
              ) {
                  nextToken
                  items {
                    id 
                    entered
                    left
                    user {
                        id
                        username
                        activecheckin
                    }
                    rating {
                      id
                      scale
                    }
                    comments (sortDirection: ASC) {
                      items {
                        id
                        content
                        date
                        user {
                          id
                          username
                        }
                      }
                    }
                  }
              }
              landlords {
                  items {
                    id 
                    landlord {
                      id
                      username
                      phonenumber
                    }
                 }
              }
              visitors {
                items {
                  id
                  visitor {
                    id
                    username
                    phonenumber
                  }
                }
                nextToken
              }
            }
          }
        }
      }
    }`;

export const deleteHomeLandlordQL = `mutation DeleteHomeLandlord($input: DeleteHomeLandlordInput!) {
      deleteHomeLandlord(input: $input) {
          home {
              id
          }
        }
      }`;

export const createImageQL = `mutation CreateImage($input: CreateImageInput!) {
          createImage(input: $input) {
            id
          }
        }
        `;
export const updateImageQL = `mutation UpdateImage($input: UpdateImageInput!) {
          updateImage(input: $input) {
            id
          }
        }
        `;

export const deleteHomeQL = `mutation DeleteHome($input: DeleteHomeInput!) {
          deleteHome(input: $input) {
              id
            }
          }`;

export const onUpdateUserCheckin = `subscription OnUpdateUserCheckin($id: String!) {
            onUpdateUserCheckin(id: $id) {
              type
              entered
              left
              rating {
                id
                scale
                feedback
                user {
                  id
                  username
                }
              }
            }
          }
          `;

export const updateUserInterfaceWithUserData = (initStarted,
  initComplete,
  responseData,
  user,
  fetchUserAppData,
  setInterfaceOwnerViewFn,
  initializeHomesAndPlacesFn,
  onActiveStreamCommentReceivedFn,
  onActiveStreamCheckinUpdateFn,
  onActiveStreamCheckinNewFn, 
  onActiveStreamCheckinDeleteFn
) => {
  if (responseData.getUser === null) {
    createUser(initStarted,
      initComplete,
      user,
      fetchUserAppData,
      setInterfaceOwnerViewFn,
      initializeHomesAndPlacesFn,
      onActiveStreamCheckinNewFn, 
      onActiveStreamCheckinDeleteFn);
  } else {
    setInterfaceOwnerViewFn(responseData.getUser.ownerview === 'owner' ? 'owner' : 'visitor');

    subscribeToActionStream(responseData.getUser.id, 
      onActiveStreamCommentReceivedFn, 
      onActiveStreamCheckinUpdateFn, 
      onActiveStreamCheckinNewFn, 
      onActiveStreamCheckinDeleteFn);

    const mergedHomes = responseData.getUser.homes.items.concat(responseData.getUser.places.items);
    if (mergedHomes.length > 0) {
      initializeHomesAndPlacesFn({
        homes: responseData.getUser.homes,
        places: responseData.getUser.places,
        activecheckin: (null === responseData.getUser.activecheckin) ? NO_ACTIVE_CHECKIN : responseData.getUser.activecheckin,
        activehomeid: (null === responseData.getUser.activehomeid) ? NO_ACTIVE_HOME : responseData.getUser.activehomeid
      });
    }
  }
}

export const fetchUserAppData = (initStarted,
  initComplete,
  user,
  setInterfaceOwnerViewFn,
  initializeHomesAndPlacesFn,
  onActiveStreamCommentReceivedFn,
  onActiveStreamCheckinUpdateFn,
  onActiveStreamCheckinNewFn, 
  onActiveStreamCheckinDeleteFn
) => {
  initStarted();
  API.graphql(graphqlOperation(getUserQL, { id: user.id, authorId: user.id }))
    .then((response) => {
      initComplete();
      updateUserInterfaceWithUserData(initStarted,
        initComplete,
        response.data,
        user,
        fetchUserAppData,
        setInterfaceOwnerViewFn,
        initializeHomesAndPlacesFn,
        onActiveStreamCommentReceivedFn,
        onActiveStreamCheckinUpdateFn,
        onActiveStreamCheckinNewFn, 
        onActiveStreamCheckinDeleteFn
      );
    }).catch(error => {
      console.log("initializeUiForUser error", error);
    });
}

export const createUser = (initStarted, initComplete, user, onCreateSuccess, setInterfaceOwnerViewFn, initializeHomesAndPlacesFn, onNewCheckinReceivedFn, onCheckinDeleteReceivedFn) => {
  const userDetails = {
    id: user.id,
    username: user.username,
    phonenumber: user.phonenumber[0],
    email: user.email,
    ownerview: 'visitor'
  };

  API.graphql(graphqlOperation(createUserQL, { input: userDetails }))
    .then((response) => {
      onCreateSuccess(initStarted, initComplete, user, setInterfaceOwnerViewFn, initializeHomesAndPlacesFn, onNewCheckinReceivedFn, onCheckinDeleteReceivedFn);
    }).catch(error => {
      console.log("Error usercreateUserGraphQLname", error);
    });
}

export const updateOwnerView = (userId, ownerview) => {
  const theInput = {
    id: userId,
    ownerview: ownerview
  };

  API.graphql(graphqlOperation(`mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(input: $input) {
          id
        }
      }
      `, {
      input: theInput
    })).catch(error => {
      console.log("updateOwnerView error", error);
    });

}

