// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:e41ba8c6-5b18-4c92-8788-c95ad4e27a1c",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_fRxuJqfkp",
    "aws_user_pools_web_client_id": "5pgqf3kqkmvdtnul5d1t3ao8lq",
    "aws_content_delivery_bucket": "maxi-proto-1",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d8e8613zb2h3j.cloudfront.net",
    "aws_user_files_s3_bucket": "maxi116c23b760cdd40deb70531eb70b477ee",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://zduo4xj525bn5ixvkfh4j6nlzu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
