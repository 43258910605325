import React, { Component } from 'react';
import { Card, CardBody, Col, Input, Button, FormGroup } from 'reactstrap';
import '../../../aws-config.js';
import { NO_ACTIVE_CHECKIN } from '../../../constants.js';
import { ON_NEW_CHECKIN_CLICKED } from '../../../store/actions.js';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PleaseWait from '../../ui/PleaseWait.js';
import { newCheckin } from '../../../utils/graphQL/checkinQL.js';

class CheckinPoster extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedHome: this.props.homesAndPlaces.length > 0 ? this.props.homesAndPlaces[0] : null,
            processing: false
        };
    }


    checkinComplete = () => {
        this.setState({ processing: false });
        this.props.postedCheckin();
    }

    newCheckin = () => {
        this.setState({ processing: true });
        newCheckin(this.state.selectedHome,
            this.props.user,
            this.props.onCheckinCreated,
            this.checkinComplete);
    }

    handleSelectionChange = (event) => {
        this.setState({
            selectedHome: this.props.homesAndPlaces[event.target.value]
        });
    }

    render() {

        return this.state.processing ? <PleaseWait /> : (<Card className="text-black bg-default">
            <CardBody style={{ backgroundColor: '#ddd' }}>
                <FormGroup row>
                    <Col xs="6" sm="8" md="9">
                        <Input
                            type="select"
                            onChange={(e) => this.handleSelectionChange(e)}                        >
                            {this.props.homesAndPlaces.map((value, index) => {
                                return <option value={index} key={'option' + index}>{value.address} #{value.id}</option>
                            })}
                        </Input>
                    </Col>
                    <Col xs="6" sm="4" md="3" style={{ textAlign: 'right' }}>
                        <Button type="submit" className="btn-pill" color="success" onClick={this.newCheckin}>Check-in</Button>
                    </Col>
                </FormGroup>
            </CardBody>
        </Card>);
    }
}

CheckinPoster.propTypes = {
    postedCheckin: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
        userLocation: (state.user.activehomeid === NO_ACTIVE_CHECKIN) ? false : true,
        homesAndPlaces: state.homes.concat(state.places)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onCheckinCreated: (value) => {
            dispatch({ type: ON_NEW_CHECKIN_CLICKED, value: value })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckinPoster);

